import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

// Ленивая загрузка страниц, улучшает производительность
const ChatWindow = lazy(() => import('./pages/ChatWindow/ChatWindow'));
const LoginPage = lazy(() => import('./pages/LoginPage/LoginPage'));
const ProtectedRoute = lazy(() => import('./components/ProtectedRoute/ProtectedRoute'));


const App: React.FC = () => {

  return (
    <Router>
      <div className="main-content">
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/chat" element={
              <ProtectedRoute>
                <ChatWindow />
              </ProtectedRoute>
            } />
            {/* Redirect users to ChatWindow by default */}
            <Route path="/" element={
              <ProtectedRoute>
                <ChatWindow />
              </ProtectedRoute>
            } />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
};

export default App;
